export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10')
];

export const server_loads = [];

export const dictionary = {
		"/course/[courseId]/chat/[chatId]": [5,[2,3]],
		"/course/[courseId]/feedback": [6,[2]],
		"/course/[courseId]/intro/name": [7,[2,4]],
		"/course/[courseId]/intro/welcome": [8,[2,4]],
		"/course/[courseId]/[...unknown]": [9,[2]],
		"/[...unkwown]": [10]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.js';